import { Component, Input } from "@angular/core";
import { Const } from "@const/Const";
import { BaseDialog } from "@dialogs/base-dlg";
import { DateUtil } from "@services/date-utils";
import { InputHelper } from "@services/input-helper";
import { OrderUtil } from "@services/order";
import { Utils } from "@services/utils";
import { RequestBuildOrderData } from "@wearewarp/types/data-model/types/PreQuickOrder";
import { ModalHelper } from "@wearewarp/ng-antd";
import { ActivatedRoute } from "@angular/router";
import { DlgCreateSuccessComponent } from "@dialogs/dlg-create-success";

@Component({
  selector: 'review-quick-orders',
  templateUrl: './index.html',
  styleUrls: ['style.scss']
})
export class ReviewQuickOrders extends BaseDialog {

  onOk: () => void;
  @Input() model;
  onProgress = false;
  displayInfo: any = {}

  constructor(
    protected activatedRoute: ActivatedRoute,
    private modalHelper: ModalHelper
  ) {
    super(activatedRoute)
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.buildDisplayInfo();
  }

  private buildDisplayInfo() {
    if (!this.model) return;
    const buildOrderData: RequestBuildOrderData = this.model.buildOrderData;
    const shipment = buildOrderData?.shipments?.[0];
    const deliveryInfos = shipment?.deliveryInfos || [];
    const pickInfo = deliveryInfos?.find(item => item.type == Const.TaskType.PICKUP);
    const dropInfo = deliveryInfos?.find(item => item.type == Const.TaskType.DROPOFF);
    const items = buildOrderData.items || [];
    this.displayInfo = {
      shipmentType: OrderUtil.getShipmentTypeName(shipment?.shipmentType),
      items,
      pickInfo: {
        locationName: pickInfo.locationName,
        address: this.getAddressText(pickInfo.addr),
        windowTime: this.getTimePeriods(pickInfo),
        isAppointmentRequired: this.isAppointmentRequired(pickInfo),
        primaryContact : this.getPrimaryContact(pickInfo),
        primaryPhoneNumber : this.getPrimaryPhoneNumber(pickInfo),
        primaryEmail : this.getPrimaryEmail(pickInfo),
        refNums : this.getReferenceNo(pickInfo),
        instructions : this.getInstructions(pickInfo),
        secondaryContact : this.getSecondaryContact(pickInfo),
        secondaryPhoneNumber : this.getSecondaryPhoneNumber(pickInfo),
        secondaryEmail : this.getSecondaryEmail(pickInfo),
        accessCode : this.getAccessCode(pickInfo),
        notes : this.getNotes(pickInfo),
      }, 
      dropInfo: {
        locationName: dropInfo.locationName,
        address: this.getAddressText(dropInfo.addr),
        windowTime: this.getTimePeriods(dropInfo),
        isAppointmentRequired: this.isAppointmentRequired(dropInfo),
        primaryContact : this.getPrimaryContact(dropInfo),
        primaryPhoneNumber : this.getPrimaryPhoneNumber(dropInfo),
        primaryEmail : this.getPrimaryEmail(dropInfo),
        refNums : this.getReferenceNo(dropInfo),
        instructions : this.getInstructions(dropInfo),
        secondaryContact : this.getSecondaryContact(dropInfo),
        secondaryPhoneNumber : this.getSecondaryPhoneNumber(dropInfo),
        secondaryEmail : this.getSecondaryEmail(dropInfo),
        accessCode : this.getAccessCode(dropInfo),
        notes : this.getNotes(dropInfo),
      }
    }

  }

  getTimePeriods(deliveryInfo){
    let timeWindowsText = deliveryInfo?.windows?.map((window) =>
      DateUtil.displayTimeWindow(window, {
        timezone: deliveryInfo?.addr?.metadata?.timeZoneStandard,
        format: "MM/DD/YYYY h:mm A",
      })
    )?.join('<br/>');
    return timeWindowsText || this.naText;
  }

  isAppointmentRequired(deliveryInfo) {
    // Nếu có dữ liệu appointmentInfo thì cũng ko hiển thị ==> mà hiển thị thay thế windowsTime
    return deliveryInfo.requiresAppointment && !Utils.isObjectNotEmpty(deliveryInfo.appointmentInfo);
  }

  getPrimaryContact(deliveryInfo){
    return (deliveryInfo?.contacts || [])?.filter(it => it?.type == Const.ContactType.primary)?.[0]?.fullName || this.naText;
  }

  getPrimaryPhoneNumber(location) {
    const phoneNumber = (location?.contacts || [])?.filter(it => it?.type == Const.ContactType.primary)?.[0]?.phone;
    return phoneNumber ? InputHelper.formatPhone(phoneNumber) : this.naText;
  }
  getPrimaryEmail(location) {
    return (location?.contacts || [])?.filter(it => it?.type == Const.ContactType.primary)?.[0]?.email || this.naText;
  }

  getSecondaryContact(deliveryInfo){
    return (deliveryInfo?.contacts || [])?.filter(it => it?.type == Const.ContactType.secondary)?.[0]?.fullName || this.naText;
  }

  getSecondaryPhoneNumber(location) {
    const phoneNumber = (location?.contacts || [])?.filter(it => it?.type == Const.ContactType.secondary)?.[0]?.phone;
    return phoneNumber ? InputHelper.formatPhone(phoneNumber) : this.naText;
  }
  getSecondaryEmail(location) {
    return (location?.contacts || [])?.filter(it => it?.type == Const.ContactType.secondary)?.[0]?.email || this.naText;
  }

  getReferenceNo(deliveryInfo){
    return (deliveryInfo?.refNums || [])?.join(', ') || this.naText;
  }

  getAccessCode(deliveryInfo){
    return deliveryInfo?.accessCode || this.naText;
  }

  getInstructions(deliveryInfo){
    return deliveryInfo?.instructions || this.naText;
  }

  getNotes(deliveryInfo){
    return deliveryInfo?.note || this.naText;
  }

  public get naText(){
    return "N/A"
  }

  onBtnApprove() {
    this.modalHelper.confirmYesNo(
      'Do you really want to create order follow the information?', 
      () => this.onDoApprove()
    );
  }

  onBtnReject() {
    this.modalHelper.confirmYesNo(
      'Do you really want to reject this form request?', 
      () => this.doReject()
    );
  }

  private doReject() {
    const requestId = this.model?.id;
    if (!requestId) return;
    let url = Const.APIURI_QUICK_ORDERS(`${requestId}/reject`)
    this.onProgress = true;
    this.api.POST(url).subscribe(
      resp => {
        this.onProgress = false;
        this.showInfo('Rejected Form Order successfully!');
        this.closeDialog();
        this.onOk();
      }, err => {
        this.showErr(err);
        this.onProgress = false;
        this.closeDialog();
        this.onOk();
      }
    );
  }

  private onDoApprove() {
    const requestId = this.model?.id;
    if (!requestId) return;
    let url = Const.APIURI_QUICK_ORDERS(`${requestId}/approve`)
    this.onProgress = true;
    this.api.POST(url).subscribe(
      resp => {
        this.onProgress = false;
        this.showDialogComplete(resp?.data?.warpId, resp?.data?.id, resp?.data?.code ?? resp?.data?.trackingCode);
        this.onOk();
      }, err => {
        this.showErr(err);
        this.onProgress = false;
        this.onOk();
      }
    );
  }

  private showDialogComplete(warpId, orderId, trackingCode) {
    this.modalService.create({
      nzContent: DlgCreateSuccessComponent,
      nzFooter: null,
      nzClosable: false,
      nzMaskClosable: false,
      nzKeyboard: false,
      nzComponentParams: {warpId, orderId, trackingCode}
    });
    this.closeDialog();
  }
    
}
